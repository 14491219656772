<template>
    <div>
        <h2><router-link :to="{ name: 'customer' }">จัดการลูกค้า</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ลูกค้า</h2>
        <v-card class="mt-4">
          <v-card-title>ลูกค้า</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid" @submit.prevent="submitForm">              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อ *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.tel"
                    outlined
                    label="เบอร์ *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.tel, 20)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.email"
                    outlined
                    label="อีเมล"                               
                    :rules="[validators.maxLengthValidator(formData.email, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col  cols="12" md="6">
                    <v-text-field 
                        v-model="formData.address"                         
                        label="ที่อยู่ *"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required, validators.maxLengthValidator(formData.address, 50)]"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense v-model="formData.city" 
                    placeholder="แขวง/ตำบล *" 
                    name="ccity" 
                    type="district" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>                                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.county" 
                    placeholder="เขต/อำเภอ *" 
                    name="ccounty" 
                    type="amphoe" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.province" 
                    placeholder="จังหวัด *" 
                    name="cprovince" 
                    type="province" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>                                  
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.postcode" 
                    placeholder="เลขไปรษณีย์ *" 
                    name="cpostcode" 
                    type="zipcode" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>  
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.number="formData.points"
                    outlined
                    label="แต้มตั้งต้น *"                               
                    :rules="[validators.required]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <delay-autocomplete
                    outlined
                    hide-selected               
                    deletable-chips
                    multiple
                    chips 
                    cache-items
                    v-model="tags"             
                    label="แท็ก"
                    placeholder="พิมพ์เพื่อค้นหาแท็ก"
                    no-data-text="ไม่พบข้อมูล"
                    :url="'/customer/tagList'"
                    dense            
                    return-object
                    ref="tagAutocomplete"
                    item-text="name"
                    item-value="id"
                    :lazySearch="true"
                  ></delay-autocomplete>
                </v-col>
              </v-row>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPostAsJson, asyncUpdateAsJson } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      tags: [],
      formData: {
        name: '',
        tel: '',
        email: '',
        address: '',
        city: '',
        county: '',
        province: '',
        postcode: '',
        points: 0,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  async created() {
    const customer_id = this.$route.params.customer_id

    if (customer_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/customer/' + customer_id)

      this.formData = {
        ...response,
        city: !!response.city ? response.city : '',
        county: !!response.county ? response.county : '',
        province: !!response.province ? response.province : '',
        postcode: !!response.postcode ? response.postcode : '',
      }

      this.tags = response.tags
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) return

      this.sendingData = true
      try {
        const customer_id = this.$route.params.customer_id
        if (customer_id == 'create')
          await asyncPostAsJson('/customer', { ...this.formData, tags: this.tags.map(item => item.id) })
        else {
          await asyncUpdateAsJson('/customer/' + customer_id, {
            ...this.formData,
            tags: this.tags.map(item => item.id),
          })
        }

        this.$router.push({ name: 'customer' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
  },
  components: {},
}
</script>